import React, { useRef, useState } from 'react';
import { COLORS } from '../config';
import { WrapperCard, Subtitle, H4 } from '../Components';
import { commonStyle } from '../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';
import ReactPlayer from 'react-player';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { fileGetByPath } from '../API';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Article = ({ article, width }) => {
  const swiperRef = useRef(null);

  const images = JSON.parse(article.images);
  
  /*** const uploadPath = process.env.NODE_ENV === 'production'
  ? 'https://espace-client-bo.sully-immobilier.fr/uploads'
  : 'https://sully-client-bo.admin-contacts.com/uploads'; ***/
  
  const uploadPath = process.env.REACT_APP_UPLOADS_URL;
  
  const docUrl = uploadPath + '/docs/program_' + article.program_id + '/' + article.document;

  return ( <
      WrapperCard row = { false } style = { { marginTop: 16, padding: 16 } } >
      <
      H4 > { article.title } < /H4> <
      Subtitle style = { { marginTop: 0 } } > { new Date(article.published_at).toLocaleString() } <
      /Subtitle> {
      images && ( <
        Swiper itemRef = { swiperRef } style = { { width: '100%', minHeight: 100 } } loop pagination = { { clickable: images?.length > 1 ? true : false } } scrollbar = { { draggable: images?.length > 1 ? true : false } } navigation = { images?.length > 1 ? true : false } autoHeight
        //   resizeObserver={true}
        updateOnImagesReady > {
          images?.map((media, i) => ( <
            SwiperSlide key = { 'journal_chantier_swiper_' + i } style = {
              {
                width: width - 16 * 2,
                height: 'auto',
                minHeight: width / 2,
              }
            } > {
              ( <
                img src = { uploadPath + '/docs/program_' + article.program_id + '/' + media } alt = { article.title }
                width = { '100%' } height = { 'auto' } style = {
                  {
                    borderRadius: commonStyle.borderRadius,
                    marginTop: 8,
                    marginBottom: 8,
                  }
                }
                />
              )
            } <
            /SwiperSlide>
          ))
        } <
        /Swiper>
      )
    }

    <
    div style = { { fontSize: 14 } } > { article.description } < /div> 
    
    {(article.document && article.document != ".") && (<a target="_blank" href={docUrl} style={{ fontSize: 14, textDecoration: 'underline', color: COLORS.primary }}> { article.document } </a>)}
    
    < /
  WrapperCard >
);
};

export default Article;